<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'PixelNoLongerDetected',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Missing pixel(s) ID',
          value: (alert) => {
            // TO TEST
            if (alert.data && alert.data.missing_pixel_id) {
              return this.$commonUtils.htmlEntitiesList(alert.data.missing_pixel_id).join(', ')
            }
            return ''
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        }
      ],
      settingsData: [],
      algoStrategyData: []
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {},
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
